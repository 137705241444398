import React from "react"
import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageWrapper, TextWrapper, HeadingWrapper } from "../components/contentwrappers"
import ActionButton from "../components/actionbutton"

const AboutPage = props => (
  <>
    <BackgroundImage
      fluid={props.data.royalCourtsImage.childImageSharp.fluid}
      alt="The Royal Courts of Justice"
      style={{ height: "100vh", width: "100vw", position: "fixed", top: "0", left: "0", zIndex: "-9" }}
    >
    </BackgroundImage>

    <Layout>
      <SEO title="About" />
      <PageWrapper>
        <HeadingWrapper>
          <h1><strong>About</strong> Us</h1>
        </HeadingWrapper>
        <TextWrapper>
          <h2><strong>Who</strong> We Are</h2>
          <p>Law Society (NI) Financial Advice Ltd (LSNIFA) was established in 1989 by the Law Society of Northern Ireland to serve the needs of solicitors, their clients and their families.</p>

          <p>Since then we have helped thousands of clients and advised on investments, pensions and other assets worth hundreds of millions of pounds.</p>

          <p>We are Independent Financial Advisers proud to put the interests of our clients at the heart of everything we do.</p>
        </TextWrapper>
        <TextWrapper>
          <h2><strong>What</strong> We Do</h2>
          <p>At LSNIFA our planner / client relationships are founded upon Integrity, Trust and Transparency.</p>

          <p>Your LSNIFA team will invest time in their relationship with you to truly understand your objectives, what's really important to you and your tolerances and attitudes towards investment risk.</p>

          <p>We understand that people have busy lives and have varying degrees of interest in financial matters and we will always strive to deliver our service in the manner that best fits with your circumstances and preferences.</p>

          <p>We typically add value to our clients by:</p>

          <ul>
            <li>recommending solutions to help you meet your financial objectives and life goals,</li>
            <li>simplifying your arrangements and giving you more control,</li>
            <li>reducing the anxiety and uncertainty over your future financial position,</li>
            <li>helping you to make smart financial decisions.</li>
          </ul>

          <div style={{ textAlign: "center" }}>
            <ActionButton toRef="/contact"><strong>Contact Us</strong> to find out more...</ActionButton>
          </div>
        </TextWrapper>

        <div style={{ textAlign: "center" }}>
          <ActionButton primary toRef="/services-for-clients">Read about our <strong>Services</strong></ActionButton>
        </div>

      </PageWrapper>
    </Layout>
  </>
)

export default AboutPage

export const pageQuery = graphql`
  query {
    royalCourtsImage: file(relativePath: { eq: "royal-courts-of-justice.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 5031, quality:60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`